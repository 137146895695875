<template>
  <div class="article_box_xq" ref="navs">
    <h1 ref="h12">{{wz.author_en?wz.author_en + ':' + wz.title_en : wz.title_en}}</h1>
    <p v-if="route.params.isen">发布日期：{{wz.created_at?wz.created_at.slice(0,10):"加载中..."}}</p>
    <p v-else>Release Date：{{wz.created_at?wz.created_at.slice(0,10):"Loading..."}}</p>
    <nav v-html="htmltext" ref="" class="nav___"></nav>
    <!-- <share class="share"/> -->
  </div>
</template>

<script setup>
import { get_Navaeticle_xq,get_aeticle_xq } from '@/api/API.js'
import { ref,onMounted,watch,onUnmounted } from 'vue'
import miiter from '@/utils/miitbus.js'
import { useRouter,useRoute, } from 'vue-router'
import Replacebaseurl from '@/hooks/useReplacebaseurl.js'
import usedownloadAttachments from '@/hooks/usedownloadAttachments.js'
import usedownloadAttachmentsen from '@/hooks/usedownloadAttachmentsen.js'
let htmltext = ref('')
let wz = ref('')
const route = useRoute()
let navs = ref(null)
// let routeData = JSON.parse(base64_.decode(route.params.obj))

// 根据路由传的id获取文章详情
let getarticle_wz_id = async (ID) => {
  const {data:res} = await get_aeticle_xq(ID)
  if(res.code == 200 && navs.value){
    wz.value = res.data
    // usedownloadAttachments函数是如果文章详情有附件，就下载附件拼接到最后。
    console.log(route.params.isen)
    if(route.params.isen){
      htmltext.value = Replacebaseurl(res.data.content_en,'https://apisem.ucas.ac.cn/') + usedownloadAttachments(res.data.pic_url)
    }else{
      htmltext.value = Replacebaseurl(res.data.content_en,'https://apisem.ucas.ac.cn/') + usedownloadAttachmentsen(res.data.pic_url)
    }

    navs.value.style.transform = 'translateY(0)'
    navs.value.style.opacity = '1'
  }
}
onMounted(() => {
  // document.documentElement.scrollTop = 0
  navs.value.style.transform = 'translateY(800px)'
  navs.value.style.opacity = '0'
  getarticle_wz_id({ id: route.params.articleid,is_en:1 })
  
})



</script>

<style lang="less" scoped>
@maxw:128rem;
.article_box_xq{
  position: relative;
  width: 100%;
  transition: ease .6s;
  h1{
    word-break: break-word;
    text-align: center;
    width: 100%;
    box-sizing: border-box;
    font-size: 39/@maxw;
    font-family: Georgia;
    font-weight: bolder;
    color: #174994;
    margin: 0;
    // padding: 0 0 20/@maxw 0;
  }
  p{
    // width: 1073/@maxw;
    height: 46/@maxw;
    background: linear-gradient(to right, #F0F0F0 0%, #D9D9D9 100%);
    border-top: 2px solid #517DBE;
    display: flex;
    align-items: center;
    justify-content: center;
    padding-left: 26/@maxw;
    font-size: 20/@maxw;
    font-family: Source Han Sans CN-Medium;
    font-weight: 500;
    color: #5A5A5A;
  }
  nav{
     width: 100%;
     transition: ease .8s;
     margin: 30/@maxw 0 0 0;
    /deep/ p,/deep/ div,/deep/ span{
      font-size: 18px;
      font-family: '微软雅黑';
      line-height: 1.5;
      color: #5A5A5A;
     }
  }
  .share{
    position: absolute;
    right: -161/@maxw;
    top: 180/@maxw;
  }
}
@media screen and (max-width:750px) {
  @maxw:50rem;
  .article_box_xq{
  h1{font-size: 39/@maxw;}
  p{height: 46/@maxw;padding-left: 26/@maxw;font-size: 20/@maxw;
  }
  nav{margin: 30/@maxw 0 0 0;}
  .share{right: -161/@maxw;top: 180/@maxw;}
}
}
</style>  